import React, { useRef } from "react";
import { useScript } from "../../../../../../Helpers/SignInScript";
import jwt_decode from "jwt-decode";
import { Environment } from "../../../../../../Environment/Settings";
import { UserService } from "../../../../../../Services/User/UserService";
import { AuthService } from "../../../../../../Services/Auth/AuthService";
import { useNavigate } from "react-router-dom";
import { AppUrl } from "../../../../../../Helpers/AppUrl";
export default function GoogleAuth() {
    let navigate = useNavigate();
    const googlebuttonref = useRef();

    const onGoogleSignIn = (user) => {

        let userCred = user.credential;
        let payload = jwt_decode(userCred);
        UserService.GoogleSignIn({
            isGmail: true, email: payload.email, emailConfirmed: payload.email_verified, imageUrl: payload.picture,
            lastName: payload.family_name, firstName: payload.given_name, userName: "", password: 'Admin@123', confirmPassword: 'Admin@123'
        }).then(data => {

            AuthService.Cookies(data);
            navigate("/" + AppUrl.UpdateProfile);
        });
    };


    //Review zaki: Get the url from .env.dev /prod
    useScript(Environment.GoogleUrl, () => {

        window.google.accounts.id.initialize({
            client_id: Environment.ClientId, // here's your Google ID
            callback: onGoogleSignIn,
            auto_select: false,
        });

        window.google.accounts.id.renderButton(googlebuttonref.current, {
            size: "medium",
        });
    });
    return (
        <div className="d-flex h-25">
            <button className="btn border-0 p-0" ref={googlebuttonref}></button>
        </div>
    );
};



////////////////////////////////////Google Button//////////////////////////////////////////////////////////////