export const Environment = {
  Production: true,
  ApiUrl: process.env.REACT_APP_API_ENDPOINT,
  Auth: 'LgAAAB_@_LCAAAAAAAAApzqDQyKfHRNLY1NbENyM80VFVRtrQ0NzYztLKwsDQ2MHIwNo6Njo830DY00tXVBgAGvJ4yLgAAAA_!__!_',
  Version: '1.0',
  // Google_Map_API_KEY: "AIzaSyD7tvrnzdWrRgYcR_xErAH0zK9Z70epM9Q", //Old Api Key
  Google_Map_API_KEY: "AIzaSyBXzQz-NNVffHRQK5W9pGdAve4aELsfP40",//new Api Key
  GoogleMapApiUrl: process.env.REACT_APP_GOOGLE_MAP_API,
  ClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  GoogleUrl: process.env.REACT_APP_GOOGLE_API,
  PortalLink: process.env.REACT_APP_PORTAL_LINK,
  Maritime: process.env.REACT_APP_MARITIME,
  Sub_Domain: process.env.REACT_APP_SUB_DOMAIN,
  AboutPage: process.env.REACT_APP_PORTAL_LINK + process.env.REACT_APP_PORTAL_ABOUT,
  NewsPage: process.env.REACT_APP_PORTAL_LINK + process.env.REACT_APP_PORTAL_NEWS,
  OffshoreNews: process.env.REACT_APP_OFFSHORENEWS,
  ContactUs: process.env.REACT_APP_PORTAL_LINK + process.env.REACT_APP_CONTACTUS,
  Terms: process.env.REACT_APP_PORTAL_LINK + process.env.REACT_APP_TERMS,
  Privacy: process.env.REACT_APP_PORTAL_LINK + process.env.REACT_APP_PRIVACY
};