import React from 'react'
import "../../Shared/css/style.css";
const Suppliers = () => {
    return (
        <section class="hero">
            <h1>Coming Soon...</h1>
        </section>
    )
}

export default Suppliers