
export const Message = {

    //Validate Error Messages
    ValidPassword: "Password Must Contain Atleast 6 Characters, One Uppercase, one Lowercase, One Number and One Special Case Number",
    ValidEmail: "Email must be valid",
    ValidFirstName: "First name must be 3 characters long",
    ValidLastName: "Last name must be 3 characters long",
    ValidUserName: "User name must be 3 characters long",
    ValidMaximumPhoneNumber: "Number must be 15 number long",
    ValidConfirmPassword: "The passwords do not match",
    ValidMinimumPhoneNumber: "Number must be 11 digit long",
    InvalidUserName: "Invalid username or password",
    TokenInvalid: "Token is invalid",
    InvalidUsernameOrEmail: 'Username or email not exists',
    DeletedSuccessfully: 'Deleted successfully',
    SavedSuccessfully: 'Saved successfully',
    EditedSuccessfully: 'Edited successfully',
    //Required Error Messages
    RequiredEmail: "Email is required",
    RequiredFirstName: "First name is required",
    RequiredLastName: "Last name is required",
    RequiredUserName: "User name is required",
    RequiredName: "Name is required",
    RequiredLocation: "Location is required",
    RequiredContactTypeId: "Contact Type is required",
    RequiredAddress: "Address is required",
    RequiredCity: "City is required",
    RequiredYearFounded: "Year Founded is required",
    RequiredStreet: "Street is required",
    RequiredSICCode: "SICCode is required",
    RequiredRevenue: "Revenue is required",
    RequiredProvince: "Province is required",
    RequiredLinkedin: "Linkedin is required",
    RequiredDomain: "Domain is required",
    RequiredAdminNote: "Admin note is required",
    RequiredRank: "Rank is required",
    RequiredCellNo: "Cell number is required",
    RequiredJob: "Job is required",
    RequiredWebsite: "Website is required",
    RequiredPhoneNumber: "Phone number is required",
    RequiredPostal: "Postal number is required",
    RequiredState: "Required",
    RequiredPassword: "Required",
    RequiredCountry: "Country name is required",
    RequiredTitle: "Title is required",
    RequiredCompanyTypeId: "Industry is required",
    RequiredCountryId: "Country is required",
    RequiredField: "Required"
}