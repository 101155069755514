import React from 'react'
import { Environment } from '../../../Environment/Settings';

const About = () => {

    window.location.href = Environment.AboutPage;
    return (
        <div></div>
    )
}

export default About