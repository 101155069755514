import React from 'react';
import Footer from './../../Shared/Layout/Footer/Footer';
import Navbar from "./../../Shared/Layout/NavBar/NavBar";
import { Outlet } from 'react-router';
import { ProtectedRoute } from '../../../Services/ProtectedRoute';
export default function WithNav() {
    return (
        <>
            <Navbar />
            <ProtectedRoute>
                <Outlet />
            </ProtectedRoute>

            <Footer />
        </>
    );
};
