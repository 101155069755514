

export default function FormInputNumber({ col, minmaxLength, type, register, maxLength, minLength, value, onChange, inputClass, placeholder, name, error, ValidMaximumPhoneNumber, ValidMinimumPhoneNumber, ValidMessage }) {


    return (

        <div className={col}>
            {minmaxLength &&
                <input type={type} className={inputClass} {...register(name, {
                    required: ValidMessage,

                })} maxLength={maxLength} minLength={minLength} value={value} onChange={onChange} name={name} placeholder={placeholder} />
            }
            {!minmaxLength &&
                <input type={type} className={inputClass} {...register(name, {
                    required: ValidMessage
                })} value={value} onChange={onChange} name={name} placeholder={placeholder} />
            }
            < br />
            <span className='text-danger'> {error}</span>
        </div>
    );
}