import { Navigate } from "react-router-dom";
import { AuthService } from './Auth/AuthService';
export const UnProtectedRoute = ({ children }) => {

    var user = AuthService.CurrentUserValue;
    var token = AuthService.CurrentTokenValue;
    if (user !== null && user !== undefined && user !== "null" && user !== "undefined" && token !== null && token !== undefined && token !== "null" && token !== "undefined") {
        // user is not authenticated

        return <Navigate to="/Home" />;
    }
    return children;
};