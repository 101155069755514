import React, { useRef } from 'react'
import { useForm } from 'react-hook-form';
import Navbar from '../../../Shared/Layout/NavBar/NavBar';
import { ProfileService } from '../../../../Services/Profile/ProfileService';
import { Message } from '../../../../Helpers/Message';
import { Validation } from '../../../../Helpers/Validation';
const UpdatePassword = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: "all" });
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = handleSubmit((data) => {

    ProfileService.UpdatePassword(data);
  })
  return (
    <>
      <Navbar />
      <div className="row mt-5 centerAlign">


        <div className="card mb-3 p-0 cardWidth">
          <div className="row g-0">

            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">Update Password</h5>

                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <input
                        type="password"
                        {...register("currentPassword", {
                          required: Message.RequiredField,
                          pattern: {
                            value:
                              Validation.ValidatePassword,
                            message:
                              Message.ValidPassword,
                          },
                        })}
                        name="currentPassword"
                        placeholder="Current Password"
                      />
                    </div>
                    <span className=" text-danger">
                      {" "}
                      {errors.currentPassword?.message}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <input
                        type="password"
                        {...register("password", {
                          required: Message.RequiredField,
                          pattern: {
                            value:
                              Validation.ValidatePassword,
                            message:
                              Message.ValidPassword,
                          },
                        })}
                        name="password"
                        placeholder="Password"
                      />
                    </div>
                    <span className=" text-danger">
                      {" "}
                      {errors.password?.message}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <input
                        type="password"
                        {...register("cPassword", {
                          required: Message.RequiredField,
                          pattern: {
                            value:
                              Validation.ValidatePassword,
                            message:
                              Message.ValidPassword,
                          },
                          validate: (value) =>
                            value === password.current ||
                            Message.ValidConfirmPassword,
                        })}
                        name="cPassword"
                        placeholder="Confirm password"
                      />
                    </div>
                    <span className=" text-danger">

                      {errors.cPassword?.message}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mt-3">
                      <button type="submit">Update</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
