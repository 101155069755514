import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImageUrl } from "../../../../Helpers/ImageUrl";
import { AuthService } from "../../../../Services/Auth/AuthService";
import { useNavigate } from "react-router-dom";
import { AppUrl } from "../../../../Helpers/AppUrl";
// import dp from '../../../../Assets/Images/dp.jpg';
import UserLogoOne from '../../../Shared/Images/user-logo-one.svg';
import UserLogoTwo from '../../../Shared/Images/user-logo-two.svg';
// import Lottie from 'react-lottie';
import lottie from "lottie-web";
import message from './lf30_editor_mndcwvyb.json';
import call from './lf30_editor_xm9jiphh.json';
import { Dropdown } from "react-bootstrap";
import Cookie from "universal-cookie";
import "./NavStyle.css";

const cookie = new Cookie();
export default function Navbar() {
  let navigate = useNavigate();
  function logout() {
    AuthService.Logout();
    navigate("/");
  }
  function Profile() {
    navigate(AppUrl.UpdateProfile);
  }


  const menulisting = cookie.get('portal') === "undefined"
    ? ''
    : cookie.get('portal');

  const userImage = cookie.get('current_user') === 'undefined'
    ? ''
    : cookie.get('current_user');



  userImage.imageUrl = userImage.imageUrl == null ? UserLogoTwo : userImage.imageUrl;


  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: message,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };

  // lottie.loadAnimation({
  //   container: element, // the dom element that will contain the animation
  //   renderer: 'svg',
  //   loop: true,
  //   autoplay: true,
  //   path: { call }, // the path to the animation json
  //   rendererSettings: {
  //     id: 'call',
  //   }
  // });

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#call"),
      animationData: call,
    });
  }, []);

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#message"),
      animationData: message,
    });
  }, []);

  return (
    <div>
      {AuthService !== null && AuthService.CurrentUserValue ? (
        <header className="header">
          <div className="header-one">
            <div className="container-width container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-8">
                  <div className="header-one-area-content d-flex align-items-center h-100">
                    <a className="contact-icons d-flex align-items-center" href="tel:+1 212 477 6700">
                      <div id="call" className="icon-holder head-icons">
                        {/* <Lottie options={defaultOptions1} /> */}
                      </div>
                      <h6 className="mb-0">+1 212 477 6700</h6>
                    </a>
                    <a className="contact-icons d-flex align-items-center contact-icons-margin" href="mailto:info@helm-track.com">
                      {/* <!-- <i className="fa fa-inbox head-icons" aria-hidden="true"></i> --> */}
                      <div id="message" className="icon-holder head-icons">

                        {/* <Lottie options={defaultOptions} /> */}
                      </div>
                      <h6 className="mb-0">info@helm-track.com</h6>
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-sm-4">
                  <ul className="navbar-nav navbar-nav-about">
                    <li className="nav-item">
                      <a className="nav-link" href="about">
                        ABOUT
                      </a>
                    </li>
                  </ul>
                  {/* <div className="header-one-social-icons d-flex align-items-center justify-content-end">
                    <i className="fa fa-twitter head-social me-2" aria-hidden="true"></i>
                    <i className="fa fa-linkedin head-social me-2" aria-hidden="true"></i>
                    <i className="fa fa-instagram head-social me-2" aria-hidden="true"></i>
                    <i className="fa fa-youtube-play head-social me-2" aria-hidden="true"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="header-two">
            <div className="container-width container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <NavLink to={AppUrl.Home} className="header-two-images">
                    <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                  </NavLink>
                </div>
                <div className="col-md-9">
                  <div className="header-two-images d-flex justify-content-end">
                    <img src={ImageUrl.HeaderTwo} alt="Headers" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-three">
            <nav className="navbar navbar-expand-lg navbar-light navbar-bg p-0">
              <div className="container-fluid container-width">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <i className="navbar-toggler-icon toggler-icon d-flex align-items-center" aria-hidden="true"></i>
                </button>
                <div className="collapse navbar-collapse header-list justify-content-between" id="navbarSupportedContent">
                  <ul className="navbar-nav mb-2 mb-lg-0">
                    {/* <li className="nav-item ">
                      <a className="nav-link " aria-current="page" href="index.html">HOME
                        <div className="icon-holder">
                          <lottie-player loop
                            src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                          ></lottie-player>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" href="vessels.html">VESSELS
                        <div className="icon-holder">
                          <lottie-player loop
                            src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                          ></lottie-player>
                        </div>
                      </a>
                    </li> */}

                    {menulisting?.map((index) => (

                      <li className="nav-item" key={index.id}>
                        <NavLink replace to={index.url}
                          className={({ isActive }) =>
                            isActive ? "nav-link active" : "nav-link"
                          }>
                          {index.name}
                          <div className="icon-holder">
                            <lottie-player loop
                              src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                            ></lottie-player>
                          </div>
                        </NavLink>
                      </li>
                    ))}
                    {/* <li className="nav-item">
                      <a className="nav-link" href="#">COMAPNIES
                        <div className="icon-holder">
                          <lottie-player loop
                            src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                          ></lottie-player>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">PROPULSION
                        <div className="icon-holder">
                          <lottie-player loop
                            src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                          ></lottie-player>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">MARITIME NEWS
                        <div className="icon-holder">
                          <lottie-player loop
                            src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                          ></lottie-player>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">ABOUT
                        <div className="icon-holder">
                          <lottie-player loop
                            src="https://assets3.lottiefiles.com/packages/lf20_tj4jecg2.json"
                          ></lottie-player>
                        </div>
                      </a>
                    </li> */}
                  </ul>
                  {/* <div className="header-buttons d-flex align-items-center">
                    <button className="header-btn bg-yellow d-flex align-items-center" onClick={logout}>
                      <div className="user-logo-img">
                        <img src={UserLogoOne} alt="UserImg" className="img-fluid rounded-circle" />
                        <img className="hover img-fluid rounded-circle" alt="UserLogoOne" src={UserLogoTwo} />
                      </div>
                      <span>Logout</span>
                    </button>
                    <button className="header-btn bg-blue d-flex align-items-center" onClick={Profile}>
                      <div className="user-logo-img">
                        <img className="hover img-fluid rounded-circle" alt="UserLogoOne" src={userImage.imageUrl} />
                      </div>
                      <span>Profile</span>
                    </button>
                  </div> */}
                  <Dropdown className="imagesize">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <div className="profileImage">
                        <img className="hover img-fluid rounded-circle" alt="UserLogoOne" src={userImage.imageUrl} />
                      </div>
                      {/* <img src={UserLogoTwo} alt="UserImg" className="img-fluid rounded-circle" /> */}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={Profile}>Profile</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className="header-buttons d-flex align-items-center">
                    <button className="header-btn bg-yellow d-flex align-items-center">
                      <div className="user-logo-img">
                        <img src={ImageUrl.UserLogo} alt="UserImg" className="img-fluid" />
                        <img className="hover img-fluid" alt="UserLogoOne" src={ImageUrl.UserLogoTwo} />
                      </div>
                      <span>LogOut</span>
                    </button>
                    {/* <button className="header-btn bg-blue d-flex align-items-center">
                      <div className="user-logo-img">
                        <img src={ImageUrl.UserLogoTwo} alt="UserlogoTwo" className="img-fluid" />
                      </div>
                      <span>Register</span>
                    </button> */}
                </div>
              </div>


            </nav>
          </div>
        </header >
      ) : (
        <div></div>
      )
      }
    </div >
  );
}

