export default function FormDropDown({ col, type, register, selectType, onChange, selectClass, value, requiredText, Data, name, error }) {

    return (

        <div className={col}>
            <div className={selectClass}>
                <select className='select-design' {...register(name, {
                    required: requiredText
                })} name={name} value={value} onChange={onChange}>
                    <option value="">Select {selectType}</option>
                    {Data.map(({ id, name }) => <option key={id} value={id} >{name}</option>)}
                </select>
            </div>
            <span className='text-danger'> {error}</span>

        </div>


    );
}