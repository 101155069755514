import React, { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Validation } from '../../../../Helpers/Validation';
import { Message } from '../../../../Helpers/Message';
import { Link } from 'react-router-dom';
import { UserService } from '../../../../Services/User/UserService';
import GoogleAuth from '../../../Shared/Layout/Custom/Button/GoogleAuth/GoogleAuth';
import { useNavigate, NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import "./SignUp.css";
import "../../../Shared/css/style.css";
import 'react-toastify/dist/ReactToastify.css';
import FormInputDiv from '../../../Shared/Layout/Custom/FormInputDiv';
import FormInputCp from '../../../Shared/Layout/Custom/FormInputCp';
import FormInputNumber from '../../../Shared/Layout/Custom/FormInputNumber';
import FormDropDown from '../../../Shared/Layout/Custom/FormDropDown';
import LoginImg from '../../../Shared/Images/login-img.png';
import { Environment } from '../../../../Environment/Settings';
import { AppUrl } from '../../../../Helpers/AppUrl';
import { ImageUrl } from "../../../../Helpers/ImageUrl";

const SignUp = () => {

  let navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [companytype, setCompanytype] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [agreeCheck, setAgreeCheck] = useState(true);
  const [isUserRegister, setIsUserRegister] = useState(false);
  useEffect(() => {
    UserService.GetData().then(data => {
      setCountries(data.data.countries);
      setCompanytype(data.data.companyType);
    });
  }, []);


  const { register, watch, handleSubmit, formState: { errors } } = useForm({ mode: "all" });
  function redirecttohome() {
    window.location.href = Environment.PortalLink;
  }
  const onSubmit = handleSubmit((data) => {

    data.agreeCheck = agreeCheck;
    setIsLoading(true);
    setAgreeCheck(true);
    data.userName = data.email;
    data.IsCompany = isUserRegister;
    data.agreeCheck = agreeCheck;
    if (isUserRegister === true) {
      data.profileCompany = {
        name: data?.companyName, title: data?.title,
        companyTypeId: Number(data?.companyTypeId), countryId: Number(data?.countryId)
      };
    }
    UserService.SignUp(data).then(data => {

      if (data.isValidated) {
        setIsLoading(false);
        setAgreeCheck(false);
        toast.success(data.message, {
        });
        navigate("/");
      }
      else if (!data.isValidated) {
        setIsLoading(false);
        setAgreeCheck(false);
        toast.error(data.message, {
        });
      }
    });

  })
  const handlePhoneNumber = event => {

    const result = event.target.value.replace(/\D/g, '');

    setPhoneNumber(result);
  };
  function AgreeToConditions() {
    setAgreeCheck(agreeCheck === true ? false : true);
  }
  function IsRegisterUser() {
    setIsUserRegister(isUserRegister === false ? true : false);
  }
  const password = useRef({});
  password.current = watch("password", "");

  return (
    <div className='container-fluid signup-middle-align signup-page'>
      <div className="row justify-content-center">
        <div className="col-md-4 p-0 no-show">
          <div className='login-main-img'>
            <img className="img-fluid" src={LoginImg} />
          </div>
        </div>
        <div className="col-lg-8 card p-0 cardWidth">
          <div className="row login-data g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width'>
                  <div className='text-center mb-4'>
                    <a onClick={redirecttohome} className="header-two-images cursor-pointer" >
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </a>
                  </div>
                  <h5 className="card-title login-text">Sign Up</h5>
                  <ToastContainer closeOnClick={true} />
                  <div className="row">
                    <div className="col-lg-12 col-md-12 mt-3 col-12">Already have an account? <Link to="/">Sign In</Link>
                    </div>
                  </div>
                  <div className='google-btn'>
                    <GoogleAuth />
                  </div>




                  <form onSubmit={onSubmit} className="google-btn">

                    <div className='row mb-3'>
                      <div className='col-md-6 small-input'>
                        <label>First Name</label>
                        <FormInputDiv register={register} inputClass={" w-100"} name="firstName"
                          error={errors.firstName?.message} type={'text'} requiredText={Message.RequiredField}
                          placeholder={"First Name"}
                        />
                      </div>
                      <div className='col-md-6 small-input'>
                        <label>Last Name</label>
                        <FormInputDiv register={register} inputClass={" w-100"} name="lastName"
                          error={errors.lastName?.message} type={'text'} requiredText={Message.RequiredField}
                          placeholder={"Last Name"}
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Enter Email</label>
                        <FormInputDiv register={register} inputClass={" w-100"} name="email"
                          error={errors.email?.message} type={'text'} requiredText={Message.RequiredField} requiredValid={Validation.ValidateEmail} ValidMessage={Message.ValidEmail}
                          placeholder={""}
                        />
                      </div>
                    </div>
                    {/* <div className='row'>
                    <div className="col-lg-12 col-md-12 col-12 mb-3 centerAlign"> */}
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Phone Number</label>
                        <FormInputNumber register={register} inputClass={" w-100"} name="phoneNumber"
                          error={errors.phoneNumber?.message} type={'text'} minmaxLength={true} minLength={11} maxLength={15} ValidMessage={false} ValidMinimumPhoneNumber={Message.ValidMinimumPhoneNumber} ValidMaximumPhoneNumber={Message.ValidMaximumPhoneNumber}
                          placeholder={"Enter phone number"}
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                        />
                      </div>

                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Enter Password</label>
                        <FormInputDiv register={register} inputClass={" w-100"} name="password"
                          error={errors.password?.message} type={'Password'} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                          placeholder={"Enter Password"}
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <label>Enter Password</label>
                        <FormInputCp register={register} inputClass={" w-100"} name="cPassword"
                          error={errors.cPassword?.message} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                          placeholder={"Please enter current password"} validConfirmPassword={Message.ValidConfirmPassword} watch={watch}
                          type={'Password'}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-lg-11 ms-2 col-md-11 col-11 d-flex justify-content-end ms-3 mt-3 ">

                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={isUserRegister} onClick={IsRegisterUser} />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Register as a company</label>
                        </div>
                      </div>
                    </div>
                    {
                      isUserRegister &&
                      <>
                        <div className='row mb-3'>
                          <div className='col-md-12'>
                            <label>Enter Title</label>
                          </div>
                          <FormInputDiv register={register} inputClass={" w-100"} name="title"
                            error={errors.title?.message} type={'text'} requiredText={Message.RequiredField}
                            placeholder={"Enter title"}
                          />
                        </div>
                        <div className='row mb-3'>
                          <div className='col-md-12'>
                            <label>Enter Company Title</label>
                          </div>
                          <FormInputDiv register={register} inputClass={" w-100"} name="companyName"
                            error={errors.companyName?.message} type={'text'}
                            placeholder={"Enter company name"}
                          />
                        </div>
                        <div className='row mb-3'>
                          <div className='col-md-12'>
                            <label>Industry</label>
                          </div>
                          <FormDropDown register={register} selectClass={"select w-100"} name="companyTypeId"
                            error={errors.companyTypeId?.message} requiredText={Message.RequiredField}
                            selectType={'industry'} Data={companytype}
                          />
                        </div>
                        <div className='row mb-3'>
                          <div className='col-md-12'>
                            <label>Country</label>
                          </div>
                          <FormDropDown register={register} selectClass={"select w-100"} name="countryId"
                            error={errors.countryId?.message} requiredText={Message.RequiredField}
                            selectType={'country'} Data={countries}
                          />
                        </div>
                      </>
                    }
                    <div className='row mb-3'>
                      <div className="col-lg-11 col-md-11 col-11 register-check mt-3 ">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" onClick={AgreeToConditions} value="" id="flexCheckDefault" />
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            I have read and agree to the Helm-Track Terms of use and Privacy Policy.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <FormButton Disable={agreeCheck} IsLoading={isLoading} ButtonText="Sign Up" icon={true} />
                      </div>
                    </div>


                  </form>
                  {/* <div className="mt-4 text-center">
                    <a onClick={redirecttohome} className="visiting-home-link">Visit to HomePage</a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default SignUp