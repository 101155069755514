//Review : use methods name for urls same as services
export const ApiUrl = {
  ///////////Company///////////////
  GetCompanyList: "company/getall",
  GetComapnyDetail: "company",
  GetData: "auth/getdata",
  GoogleSignIn: "auth/googlesignin",
  GetDataCompany: "company/getdata",
  DeleteCompany: "company",
  SaveCompany: "company",
  GetCompaniesByFlag: "company/byflag",

  /////////Vessel//////////////
  GetVesselList: "vessel/getall",
  GetVesselDetail: "vessel",
  GetVesselData: "vessel/getdata",
  GetVesselType: "vessel/getvesseltype",
  AddVessel: "vessel",
  DeleteVessel: "vessel/delete",
  GetCompanyType: "vessel/getcompanytype",
  GetAllVesselDetail: "vesselInfo",
  GetVesselByUserId: "vessel/getvesselbyuserid",
  GetVesselByFlag: "vessel/getbyflag",
  GetVesselByType: "vessel/getbytype",

  //////////Profile///////////////
  GetProfile: "profile/get",
  UpdateProfile: "profile/save",
  //////auth////////////
  Signup: "auth/signup",
  Signin: "auth/signin",
  ForgotPassword: "auth/forgotpassword",
  ResetPassword: "auth/resetpassword",
  UpdatePassword: "auth/updatepassword",
  AccountConfirmation: "auth/confirmaccount",
};
