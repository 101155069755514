import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserService } from '../../../../Services/User/UserService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthService } from '../../../../Services/Auth/AuthService';
import { Message } from '../../../../Helpers/Message';
const ConfirmAccount = () => {

  const { email } = useParams();
  const [alreadyConfirmed, setAlreadyConfirmed] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  useEffect(() => {

    AuthService.Logout();

    UserService.ConfirmAccount({ email }).then(data => {
      if (data.message === Message.InvalidUserName) {
        setAlreadyConfirmed(true);
        setTokenValid(false);
        toast.error(data.message, {
        });
      }
      else if (data.message === Message.TokenInvalid) {
        setTokenValid(true);
        setAlreadyConfirmed(true);
        toast.error(data.message, {
        });
      }
    });
  }, [email]);
  return (
    <div className='container container-middle-align'>
      <ToastContainer closeOnClick={true} />
      <div className="row ">
        <div className="card mb-3 p-0 cardWidth">
          <div className="row g-0">
            <div className="col-12">
              <div className="card-body">
                <h5 className="card-title centerAlign login-text fs-4">Account Activation</h5>


                <div className="row centerAlign">
                  <div className="col-lg-12 col-md-12 col-12 mt-3 centerAlign">

                    {!alreadyConfirmed && <div>Your account has been activated </div>}
                    {alreadyConfirmed && <div>

                      {!tokenValid && <div>Your account was already activated </div>}
                      {tokenValid && <div>Your token is expired</div>}
                    </div>}

                  </div> </div>
                <div className="row centerAlign">
                  <div className="col-lg-12 col-md-12 col-12 mt-3 centerAlign"><Link to="/">Back to login?</Link>
                  </div> </div></div>
            </div>
          </div></div></div></div>
  )
}

export default ConfirmAccount