import React, { useEffect, useState } from 'react'
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { UserService } from '../../../../Services/User/UserService';
import { AuthService } from '../../../../Services/Auth/AuthService';
import { Message } from '../../../../Helpers/Message';
import { Validation } from '../../../../Helpers/Validation';
import { ToastContainer, toast } from 'react-toastify';
import FormInputDiv from '../../../Shared/Layout/Custom/FormInputDiv';
import FormInputCp from '../../../Shared/Layout/Custom/FormInputCp';
import 'react-toastify/dist/ReactToastify.css';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import { AppUrl } from '../../../../Helpers/AppUrl';
import { ImageUrl } from "../../../../Helpers/ImageUrl";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    AuthService.Logout();
  }, []);
  const { email } = useParams();
  let navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: "all" });

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    UserService.ResetPassword(data).then(data => {

      setIsLoading(false);
      if (!data.isValidated) {
        toast.error(data.message, {
        });
      }
      else {
        toast.success(data.message, {
        });
      }
      navigate("/");
    });
  })
  return (
    <div className='container container-middle-align'>
      <div className="row ">
        <div className="card mb-3 p-0 cardWidth">
          <div className="row g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width'>
                  <div className='text-center mb-4'>
                    <NavLink to={AppUrl.Home} className="header-two-images">
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </NavLink>
                  </div>
                  <h5 className="card-title centerAlign login-text fs-4">Reset Password</h5>
                  <ToastContainer closeOnClick={true} />
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <input type='hidden' value={email} {...register('email', { required: true })} name='email' />
                      </div>
                    </div>
                    <div className='row centerAlign'>
                      <FormInputDiv col={"col-lg-3 col-md-3 col-3 ms-4"} register={register} inputClass={"effect-16 w-100"} name="password"
                        error={errors.password?.message} type={'Password'} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                        placeholder={"Enter Password"}
                      />
                    </div>
                    <div className='row centerAlign'>
                      <FormInputCp col={"col-lg-3 col-md-3 col-3 ms-4"} register={register} inputClass={"effect-16 w-100"} name="cPassword"
                        error={errors.cPassword?.message} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                        placeholder={"Please enter current password"} validConfirmPassword={Message.ValidConfirmPassword} watch={watch}
                        type={'Password'}
                      />
                    </div>
                    <div className="row centerAlign">
                      <div className="col-lg-3  mt-4 col-md-3 col-3 centerAlign">
                        <FormButton IsLoading={isLoading} ButtonText="Reset" />
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;


