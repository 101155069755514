
import Banner from "../Components/Shared/Images/banner.png"
import SeaImg from "../Components/Shared/Images/sea-img.png"
import SeaWaves from "../Components/Shared/Images/sea-waves.png"
import UserLogo from "../Components/Shared/Images/user-logo-one.svg"
import UserLogoTwo from "../Components/Shared/Images/user-logo-two.svg"
import Logo from "../Components/Shared/Images/header-logo.png"
import HeaderTwo from "../Components/Shared/Images/header-two-img.png"
import HomeVessel from "../Components/Shared/Images/vessel-home.jpg"
import FlFive from "../Components/Shared/Images/fl-five.png"
import Comerica1x from "../Components/Shared/Images/comerical1x.png"
import Maritime from "../Components/Shared/Images/maritime.png"
export const ImageUrl = {
    Banner: Banner,
    SeaImg: SeaImg,
    SeaWaves: SeaWaves,
    UserLogo: UserLogo,
    Logo: Logo,
    HeaderTwo: HeaderTwo,
    UserLogoTwo: UserLogoTwo,
    HomeVessel: HomeVessel,
    FlFive: FlFive,
    Comerica1x: Comerica1x,
    Maritime: Maritime,
};
