import React from 'react'
import "./Login.css"
import "../../../Shared/css/style.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { AuthService } from '../../../../Services/Auth/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import { Validation } from '../../../../Helpers/Validation';
import { Message } from '../../../../Helpers/Message';
import GoogleAuth from '../../../Shared/Layout/Custom/Button/GoogleAuth/GoogleAuth';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import FormInputDiv from '../../../Shared/Layout/Custom/FormInputDiv';
import { Environment } from '../../../../Environment/Settings';
import { AppUrl } from '../../../../Helpers/AppUrl';
import LoginImg from '../../../Shared/Images/login-img.png';
import { ImageUrl } from "../../../../Helpers/ImageUrl";

const Login = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "all" });
  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    AuthService.Login(data.email, data.password).then(data => {

      setIsLoading(false);
      if (!data.isValidated) {
        toast.error(data.message, {
        });

      }

      else {
        // toast.success(data.message, {
        // });
        //navigate("/" + AppUrl.Home);

        window.location.href = Environment.PortalLink;
      }
    })
  })
  function redirecttohome() {
    window.location.href = Environment.PortalLink;
  }
  return (
    <div className='container-fluid container-middle-align login-page'>
      <div className="row justify-content-center">
        <div className="col-md-4 p-0 no-show">
          <div className='login-main-img'>
            <img className="img-fluid" src={LoginImg} />
          </div>
        </div>
        <div className="col-lg-8 card p-0 cardWidth">
          <div className="row login-data g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width'>
                  <div className='text-center mb-4'>
                    <a onClick={redirecttohome} className="header-two-images cursor-pointer">
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </a>
                  </div>
                  <h5 className="card-title login-text">Sign In</h5>
                  <ToastContainer closeOnClick={true} />
                  <div className="row">
                    <div className="col-lg-12 col-md-12 mt-3 col-12">Don't have an account? <Link to="/signup">Signup</Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 google-btn col-12">
                      <GoogleAuth />
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className='enter-form'>
                      <div className='row'>
                        <label>Enter Email</label>
                        <FormInputDiv col={"col-md-12"} register={register} inputClass={"w-100"} name="email"
                          error={errors.email?.message} type={'text'} requiredText={Message.RequiredField} requiredValid={Validation.ValidateEmail} ValidMessage={Message.ValidEmail}
                          placeholder={"username@domain.com"}
                        />
                      </div>
                      <div className='row mt-3'>
                        <label>Enter Password</label>
                        <FormInputDiv col={"col-md-12"} register={register} inputClass={"w-100"} name="password"
                          error={errors.password?.message} type={'Password'} requiredText={Message.RequiredField}
                          placeholder={"•••••••••••"}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center sign-in-area">
                      <div >
                        <FormButton IsLoading={isLoading} ButtonText="Sign In" icon={true} />
                      </div>
                      <div className="">
                        <Link to="/forgotpassword">Forgot Password?</Link>
                      </div>
                    </div>
                  </form>
                  {/* <div className="mt-4 text-center">
                    <a onClick={redirecttohome} className="visiting-home-link">Visit to HomePage</a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Login