export const AppUrl = {
    Home: "home",
    Company: "companies",
    CompanyDetail: "/company",
    Vessel: "vessels",
    // VesselInfo: "Vessel/:Id",
    AddNewVessel: "/setup",
    //ViewVessel: "/vessel",
    ViewVessel: "vessels/:Name",
    //UpdateVessel: "/:Code",
    UpdateProfile: "profile",
    Signup: "signup",
    ForgetPassword: "forgotpassword",
    UpdatePassword: "updatepassword",
    ResetPassword: "setpassword/:email",
    ConfirmAccount: "confirmaccount/:email",
    MartimeNews: "news",
    //MartimeNews: "https://www.maritimeprofessional.com/news",
    About: "about",
    Propulsion: "propulsion",
    OffShore: "offshore",
    ContactUs: "contactus",
    Terms: "terms",
    Privacy: "privacy",
    AudinenceBuilder: "audience",
    Suppliers: "suppliers"
};