export default function FormInputDiv({ row, col, type, register, inputClass, placeholder, name, error, requiredText, requiredValid, ValidMessage }) {

    return (

        <div className={col}>
            <input type={type} className={inputClass} {...register(name, {
                required: requiredText, pattern: {
                    value: requiredValid,
                    message: ValidMessage
                }
            })} name={name} placeholder={placeholder} />
            <br />
            <span className='text-danger'> {error}</span>
        </div>


    );
}