import FooterBackground from '../../Images/header-logo.png'
import { AuthService } from '../../../../Services/Auth/AuthService';
import Cookie from "universal-cookie";
import { NavLink } from 'react-router-dom';
import { AppUrl } from '../../../../Helpers/AppUrl';
import { useState } from 'react';
import { useEffect } from 'react';

const cookie = new Cookie();
export default function Footer() {
    const [user, setUser] = useState('');

    useEffect(() => {
        setUser(cookie.get("token"));
    }, [user])

    return (
        <div>
            {AuthService !== null && AuthService.CurrentUserValue ? (
                <footer className="footer">
                    <div className="container-width container-fluid">
                        <div className="row marginb">
                            <div className="col-md-4">
                                <div className="footer-logo">
                                    <img src={FooterBackground} alt="footer-img" className="img-fluid" />
                                </div>
                                <p>Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque nunc in aliquet. Donec congue, nunc vel tempor congue, enim. Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque nunc in aliquet. Donec congue, nunc vel tempor congue, enim.</p>
                                {/* <a className="read-more" href="#">Read More <i className="fa fa-arrow-right ms-2" aria-hidden="true"></i></a> */}
                            </div>
                            <div className="col-md-8 footer-links-container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="footer-link">
                                            <p>Vessels</p>
                                            <ul className="footer-link-ul">
                                                <li><NavLink replace to={AppUrl.Vessel}>Vessel Database</NavLink></li>
                                                <li><NavLink replace to={AppUrl.Vessel}>Advance Vessel Search</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="footer-link">
                                            <p>Companies</p>
                                            <ul className="footer-link-ul">
                                                <li><NavLink replace to={AppUrl.Company}>Ship Owners</NavLink></li>
                                                <li><NavLink replace to={AppUrl.Company}>Shipbuilders</NavLink></li>
                                                <li><NavLink replace to={AppUrl.Company}>Ports</NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-link">
                                            <p>Maritime Sites</p>
                                            <ul className="footer-link-ul">
                                                <li><NavLink replace to={AppUrl.MartimeNews}>Marine News</NavLink></li>
                                                <li><NavLink replace to={AppUrl.OffShore} >Offshore News</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="footer-link">
                                            <p>Explore</p>
                                            <ul className="footer-link-ul">
                                                <li><NavLink replace to={AppUrl.MartimeNews}>Marine Engines</NavLink></li>
                                                <li><NavLink replace to={AppUrl.MartimeNews}>Maritime News</NavLink></li>
                                                <li><NavLink replace to={AppUrl.MartimeNews}>Advertise</NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column justify-content-between">
                                        <div className="footer-link">
                                            <p>Contact us</p>
                                            <ul className="footer-link-ul">
                                                <li><i className="fa fa-envelope me-1" aria-hidden="true"></i> support@helm-track.com</li>
                                                <li><i className="fa fa-map-marker me-1" aria-hidden="true"></i> New street DF m block LasAngle New York</li>
                                                <li><i className="fa fa-phone me-1" aria-hidden="true"></i> +1 212 477 6700</li>
                                                <li><i className="fa fa-arrow-right me-1"></i><NavLink replace to={AppUrl.ContactUs}>Contact us</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="social-links ">
                                            <ul className="d-flex">
                                                <li><i className="fa fa-facebook" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-google-plus" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-twitter" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container-fluid container-width">
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="mb-0 me-2">©2022 New Wave Media. All right Reserved.</p>
                                <ul className="d-flex ul-color">
                                    <li><NavLink replace to={AppUrl.Terms}>Terms.</NavLink></li>
                                    <li><NavLink replace to={AppUrl.Privacy}>Privacy.</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>) : (
                <div></div>
            )
            }
        </div >
    );
}